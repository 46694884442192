.controlArea {
  /* width: 85vw; */
  width: 100%; 
  background-color: #f8f8f5;
  /* height: 100vh; */
  display: flex;
  justify-content: left;
  align-items: left;
}

.contextArea {
  /* width: 40vw; */
  width: 60%;
  padding: 2% !important;
}

.mapArea {
  width: 45vw;
}

.stopCard {
  padding: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.stopCardTitle {
  font-size: 16px;
  width: 260px
}

.cardHeaders {
  vertical-align: top;
}

.cardButton {
  float: right;
  margin-left: auto;
}

.mainTourHeader {
  width: 200px;
  height: 38px;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: -0.1px;
  text-align: left;
  color: #39415e;
  margin-bottom: 2%;
}

.subTourHeader {
  width: 532px;
  height: 24px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #6e6d71;
}