.innerLocatioForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 55vh; */
  margin-top: 5%;
  /* margin-bottom: 5%; */
}

.photoLocation{
  margin-bottom: 3%;
}

.allLocationForm {
  margin: 3%;
  height: 76vh;
  width: 32vw;
}

.saveNcancleButton{
  /*margin-top: auto;*/
}

.allCreation {
  margin: 3%;
  width: 36vw;
  width: 100%;
}

.locationInput {
  width: 508px;
  width: 100%;
 /*  height: 55px; */
  border-radius: 3px !important;
  background-color: #f6f6f6 !important;
}
.smallNameInput {
  margin-bottom: 80px;
}

.inputLocationHeader {
  width: 508px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: bold;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6e6d71;
  background-color: #ffffff;
  padding-bottom: 1px;
}

.textLocation {
  width: 508px;
  border-radius: 3px !important;
  padding-bottom: 20px;
}

.PlacesAutocompleteItemOld {
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
}

.PlacesAutocompleteListOle {
  width: 459px !important;
  height: 19px;
  font-family: PloniDL1.1AAA;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff6008;
  background-color: #f6f6f6 !important;
}

.PlacesAutocompleteItem {
  position: "absolute";
  z-index: 10000;
  width: 100%;
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 75px;
  text-align: left;
  border-top: 2px solid #e6e6e6;
  font-size: 16px;
  color: #ff6008;
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
  background-color: #ffffff;
}

.PlacesAutocompleteList {
  position: "absolute";
  z-index: 10000;
  width: 475px;
  height: 500px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.suggestionHeader {
  position: "absolute";
  z-index: 10000;
  font-family: PloniDL1.1AAA;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ff6008;
}

.diractionArea{
  margin-top: 3%;
}

.locationFooter {
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
  margin: 6% 0 8% 0;
}

.cordInput {
  width: 150px !important;
  height: 55px;
  border-radius: 3px;
  background-color: #f6f6f6;
}

.saveButton {
  margin-top: 30px;
  cursor: pointer;
}

.locPicArea{
  margin-top: 15px;
  margin-bottom: 15px;
  object-fit: contain;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}

.latLngArea{
  display: flex;
  flex-direction: row;
}

.locationButtons{
  margin: 45px 0 0 25px;
}

.saveLocationButton{
  width: 160px;
  height: 46px;
  border-radius: 24px;
  background-color: #bdee4a;
  object-fit: contain;
  color: #6e6d71;
}

.cancleLocationButton{
  width: 160px;
  height: 46px;
  border-radius: 24px;
  background-color: #ff8738;
  object-fit: contain;
  color: #e6e6e6;
}