/* Login Page */

/*
.ui.form {
    border: solid 1.2px #ffffff;
}
*/

.form-bg {
    background-color: #39415e;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 25%;
}

.conference-form{
    background-color: #39415e;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3%;
    width: 90%;
    margin:auto;
}

.mainTitleConference {
   
   /*  height: 38px; */
    font-family: PloniDL1.1AAA;
    font-size: 31.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #fefefe;
    margin: 0 auto;
    margin-bottom: 20px;
}

.afterSubmit{
    height: 100%;
}

.formLable {
   
    font-family: PloniDL1.1AAA;
    font-size: 17.6px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: right;
    color: #fefefe;
    margin:  auto;
    margin-bottom: 3px;
}

.mainTitle {
    width: 216px;
    height: 38px;
    font-family: PloniDL1.1AAA;
    font-size: 31.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #fefefe;
    margin: 0 auto;
    margin-bottom: 20px;
}

.ui.form.input {
    /*width: 366px;
    height: 38px;
    border: solid 1.2px var(--white);
    border-radius: 18px;*/
    width: 336px;
    height: 38px;
    border-radius: 19.2px;
    background-color: #ffffff;
    border-radius: 35px;
}

.submitButton {
    width: 171px;
    height: 46px;
    background-color: #f7a039;
    border-radius: 50px;
    font-size: 24px;
    color: #ffffff;
    border: none;
}

.goToRegister {
    width: 366px;
    height: 42px;
    font-family: PloniDL1.1AAA;
    font-size: 15.6px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: auto;
    margin-top: 10px;
}

.agreeTAC{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
}

.agree{
    position:relative !important;
    left:0px !important;
    top: 0px !important;
  width: 20px;
  height: 20px;
}

.agreeText{
    font-size: 15.6px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
       
}

.socialLogin {
    width: 400px;
    margin-left: 12%;
    margin-top: 10%;
}

.ui .large .form {
    width: 366px;
    height: 38px;
    border-radius: 30px;
    background-color: #ffffff;
}

.ui.teal.button {
    width: 171px;
    height: 46px;
    border-radius: 24px;
    background-color: #fa6400;
    margin: 0 auto;
}

.ui.facebook.button {
    width: 304px;
    height: 46px;
    border-radius: 23px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-color: #97a425;
}

.ui.google.plus.button {
    width: 304px;
    height: 46px;
    border-radius: 23px;
    box-shadow: 0 1px 1px 0 var(--black-14);
    background-color: #ffffff;
}

.allform {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5%;
}

.stopForm{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5%;
}

.changRegister {
    width: 304px;
    height: 46px;
    border-radius: 23px;
    box-shadow: 0 1px 1px 0 var(--black-14);
    background-color: #fa7979;
}

/* End Login Page */

/* Start Tour Form */

.ui.header {
    margin: 2% !important;
    width: 160px !important;
}

.formGroup {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    margin: 3%;
}

.formOne {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
}

.formOne .field {
    border: solid 1px #6e6d71;
}

.equipmentList{
    display: flex;
    flex-direction: column;
}

.field .input:focus {
    border-radius: 3px;
    border: solid 2px #97a50f;
    background-color: #f6f6f6;
}

.langField {
    padding: 5%;
    width: 400px;
}

.ui.selection.dropdown {
    padding: 5%;
    width: 400px ;
    height: 55px;
}

.ui.form textarea {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 5%;
    width: 400px; 
    height: 155px;
    border-radius: 3px;
    background-color: #f6f6f6;
}
.ui.form .field{
    width: 400px;
}
@media only screen and (max-width: 1400px) {
    .ui.form textarea {
        width: 350px;
    }

    .ui.form .field{
        width: 320px;
    }
    .ui.form input:not([type]){
        width: 320px;
    }
}



.ui.selection.dropdown {
    border-radius: 1px;
    border: solid 1px #6e6d71;
    background-color: #f6f6f6;
}

.ui.multiple.dropdown {
    margin: inherit;
}

.ui.form input:not([type]), .ui.form input[type="number"], .ui.form input[type="textarea"] {
    padding: 5%;
    width: 100%;
    height: 55px;
    border-radius: 3px;
    background-color: #f6f6f6;
}


.input-range {
    width: 380px !important;
    height: 8px !important;
}

.input-range__track--active, .input-range__slider {
    background: #8ac0c4 !important;
    border-radius: 5px;
    background-color: #bdcd31 !important;
}

.input-range__slider {
    width: 20px !important;
    height: 20px !important;
    border: solid 2px #89c0c4 !important;
    background-color: white !important;
    margin-bottom: 6px !important;
    margin-top: -10px !important;
}

.input-range__label-container {
    width: 15px;
    height: 26px;
    font-family: PloniDL1.1AAA;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #89c0c4;
    margin-bottom: 2% !important;
}

.saveFormButton {
    width: 199px;
    height: 46px;
    border-radius: 24px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.21);
    background-color: #f8a12a;
    color: #ffffff;
    font-size: 17px;
    font-weight: bold;
    margin: auto;
    margin-left: 38%;
    margin-top: 2%;
}

.saveFormButton:disabled {
    background-color: #f8a22a6c;
}

/* End Tour Form */

/*User form*/

.questionText {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262627;
    margin-bottom: 5px;
    margin-top: 20px;
}

/*profile media*/

.profilePicture {
    width: auto;
    height: 340px;
    margin: 4%;
    border-radius: 2.4px;
    background-color: #f6f6f6;
    font-family: PloniDL1.1AAA;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #6e6d71;
}

.profileSave {
    width: 171px;
    height: 46px;
    border-radius: 24px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.21);
    background-color: #f8a12a;
    text-align: center;
    color: #ffffff;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: 0.32px;
    margin: 2%;
}

.combineMedia {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}

.profileImgObj {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3%;
}

.profileImg {
    width: 220px;
    height: 220px;
    margin: auto;
}

.profileHeader {
    font-family: PloniDL1.1AAA;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #6e6d71;
}

div[disabled] {
    pointer-events: none;
    opacity: 0.3;
}

.vertical {
    border-left: 3px solid black;
    height: 250px;
    position: absolute;
    left: 50%;
}

.resetEmail{
    margin:6%;
    width: 350px;
    height: 40px;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 var(--black-14);
}

.resetTitle{
    width: 316px;
    height: 38px;
    font-family: PloniDL1.1AAA;
    font-size: 28.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #fefefe;
    margin: 0 auto;
}

.resetButton{
    width: 221px;
    height: 46px;
    background-color: #f7a039;
    border-radius: 50px;
    font-size: 20px;
    color: #ffffff;
    border: none;
    margin-bottom: 8px;
}

.checkBoxs{
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    margin-bottom: 3%;
}