* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.indexPage {
    background-color: #ffffff;
}

.footerIndex {
    width: 1920px;
    height: 481px;
    object-fit: contain;
    background-image: url('../../images/foterIndex.svg');
}

.headerIndex {
    /*width: 1920px;*/
    width:100%;
    height: 920px;
    position: relative;
    background: #38405e;
    text-align: center;
    overflow: hidden;
    padding-top: 12px;
    background-image: url("../../images/waveBG.svg") ;
    background-repeat: no-repeat;
}

.centerNav {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.centerNavHe{
   justify-items: right;
   justify-content: right;
    flex-direction: row-reverse;
}

.hebMenu{
    display: flex;
    flex-direction: row-reverse;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline;
    overflow: hidden;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 6px 8px;
    text-decoration: none;
}

nav {
    margin: 5px;
    padding: 5px;
    overflow: hidden;
}

ul {
    list-style: none;
    text-align: center;
    overflow: hidden;
}

li {
    display: inline-block;
    margin: 0 18px;
    padding: 5px 0;
}

a {
    padding: 5px 20px;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
}



.loginButton {
    margin-left: 40px;
    width: 171px;
    height: 46px;
    border-radius: 24px;
    background-color: #f8a12a;
    float: bottom;
    font-family: PloniDL1.1AAA;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: 0.32px;
    text-align: center;
    color: white;
}

.registerButton {
    margin-left: 20px;
    width: 171px;
    height: 46px;
    border-radius: 24px;
    background-color: #38405e;
    float: bottom;
    font-family: PloniDL1.1AAA;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: 0.32px;
    text-align: center;
    color: #f8a12a;
    border-color: #f8a12a;
}

#section1 {
    /* padding-top: 50px !important; */
    margin-bottom: 10px;
    /* margin-top: 50px !important; */
    behavior: "smooth"
}

.whoWeAreHeader {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 552px;
}

.whoWeAreHeaderSmall {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7e8085;
}

.whoWeAreHeaderBig {
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #2c2e30;
}

.whoWeAre {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.whoWeAreItem {
    width: 332px;
    margin-left: 30px;
    margin-right: 30px;
}

.whoWeAreItemHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: #ff9900;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.hebrewDiraction{
    flex-direction: row-reverse;
}

.rtlText{
    text-align: right;
    direction:rtl;
}

.whoWeAreItemText {
    font-size: 16px;
}
.appLinks{
    width: 80%;
    text-align: center;
    margin-bottom: 100px;
    margin: 100px auto;
}

@media only screen and (max-width: 1400px) {
   .appLinks{
       margin-top: 5px;
       width: 100%;
   }
}

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
    position: fixed;
    top: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    background-color: #38405e;
    width: 400px;
    height: 60px;
  }
/* Style the social media icons with color, if you want */
.icon-bar a:hover {
    background-color: #e4e4e451;
  }

  /* Style the icon bar links */
.icon-bar a {
    /* display: block; */
    text-align: center;
    padding: 14px;
    transition: all 0.3s ease;
     color: white;
    font-size: 20px; 
  }

.getAppButton{
    width: 20%;
    height: 100%;
}

.socialIcon{
    width: 10%;
    margin: 2.5% 1%;
}


@media only screen and (max-width: 600px) {
    
    .getAppButton{
        width: 110px;
        height: 55px;
    }

    .socialIcon{
        width: 35px;
    }
}

/* Responsive  */

@media only screen and (max-width: 600px) {
    .bigLogo{
        width: 90%;
    }
    .headerIndex {
        /*width: 1920px;*/
        width:100%;
        height: 500px;
        position: relative;
        background: #38405e;
        text-align: center;
        overflow: hidden;
        padding-top: 12px;
        background-image: url("../../images/waveBG.svg");
    }

    #section1 {
      display: flex;
      flex-direction: column;
      flex-flow: column;
      margin-bottom: 10px;
      width: 100%;
    }
    .whoWeAreHeader{
        width: 93%;
    }

    .whoWeAreHeaderBig{
        width: 93%;

    }
    .whoWeAreHeaderSmall{
        width: 93%;
    }
    .whoWeAre {  
        flex-direction: column;
        margin: auto;
        text-align: center;
    }
    .whoWeAreItem {
        width:90%;
        margin-left: 1px;
        margin-right: 1px;
        margin: auto;
        padding: 5%;
    }
    .whoWeAreItemHeader{
        margin-bottom: 4%;
    }

  }


  #section2 {
    width: 1920px;
    height: 704px;
    background-color: #f8f8f5;
    padding-top: 80px;
}

.carouselPic {
    width: 390px;
    height: 751px;
    object-fit: contain;
    margin-right: 40px;
    margin-top: 107;
}

.carouselItem {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* width: 980px; */
    justify-items: center;
    margin-top: 107;
}

.hiwSmallTitle {
    width: 155px;
    height: 26px;
    font-family: PloniDL1.1AAA-D;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* text-align: left; */
    color: #848484;
    margin-bottom: 25px;
}

.caroulelContext {
    margin-top: 27px;
}

.carouselHeder {
    width: 341px;
    height: 92px;
    font-family: PloniDL1.1AAA;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
   /*  text-align: left; */
    color: #ff9900;
}

.carouselText {
    width: 494px;
    height: 149px;
    font-family: PloniDL1.1AAA;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    /* text-align: left; */
    color: #2c2e30;
}

.carousel {
    /* width: 980px !important; */
    width: 80%;
}

.theCarousel {
    margin: auto;
width: 100%; 
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   /* width: 980px; */
   justify-items: center;
    padding-top: 107px !important;
}

/* Responsive  */

@media
 only screen and (max-width: 600px) {
    #section2{
        padding-top: 5px;
        width: 100%;
        padding: 15px;
    }
    .theCarousel {
       margin: auto;
       width: 100%; 
       display: flex;
       flex-direction: column-reverse;;
       justify-content: space-evenly;
       /* width: 980px; */
       justify-items: center;
        padding-top: 40px !important;
        height: 1100px;  
    }
    .carouselItem {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        /* width: 980px; */
        justify-items: center;
        margin-top: 1px;
    }
    .hiwSmallTitle{
     padding-bottom: 10px;   
    }
    .caroulelContext{
        /* width: 350px; */
        width: 100%;
        text-align: left;
        margin: 15px;

    }
    .hiwSmallTitle{
        margin: auto;
    }
    .carouselHeder{
        /* width: 350px; */
        width: 93%;
        text-align: center;
    }

    .carouselText{
        width: 93%;
        text-align: center;
        height: 100px;
        padding:1.5%;
    }
    .carouselPic{
        width: 93%;
        margin: 0 auto;
        text-align: center;
    }
    .carouselImg{
        height: 670px;
    }

}

html {
    scroll-behavior: smooth;
}





#section3 {
    margin-top: 350px;
    height: 650px;
    margin-bottom: 200px;
}

.guideSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.guideSectionContext {
    width: 542px;
}

.guideSectionText {
    line-height: 1.63;
    font-size: 16px;
    padding-top: 40px;
    padding-bottom: 25px;
}

#ovalBG {
    pointer-events: none;
    z-index: -1;
    object-fit: cover;
    margin-right: 150px;
}

.guideSectionButton {
    margin-left: 40px;
    width: 250px;
    height: 46px;
    border-radius: 24px;
    background-color: #f8a12a;
    float: bottom;
    font-family: PloniDL1.1AAA;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: 0.32px;
    text-align: center;
    color: white;
}


.guideSelectionSrc{
    border-radius: 240px 90px 110px 200px;
}
@media
 only screen and (max-width: 600px) {
    #section3{
        width: 100%;
        max-width: 600px;
        margin: 300px auto auto;
        padding: 15px;
        height: 550px;
        /* width: 370px;
        height: 599px;
        margin-bottom: 1px; */
    }
    .guideSection {
        display: flex;
        flex-direction: column;
    }
    .guideSelectionPic{
        display:none;
        display: none;
        border-radius: 10px 20px 75px 75px;
    }

    .guideLogo{
        width: 93%;

    }

    .guideSectionContext{
        width: 93%;
        text-align: center;
        margin: 3%;
    }

    .guideSectionButton{
        margin-left: 4px;
    }

    .orangeButton{
        width: 120px;
        font-style: normal;
        font-size: 20px !important;
    }
    
}

#section4 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.businessSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.businessContext {
    width: 542px;
    margin-left: 45px;
}

.businessText {
    line-height: 1.63;
    font-size: 16px;
    padding-top: 40px;
    padding-bottom: 25px;
}

.businessImg {
    margin-right: 80px;
}

@media
 only screen and (max-width: 600px) {
    #section4{
        width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 15px;
    }
    .businessSection {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .businessImg{
        display:none;
        display: none;
        
    }

    .businessLogo{
        width: 93%;

    }

    .businessContext{
        width: 93%;
        text-align: center;
        margin: 2%;
    }

    .guideSectionButton{
        margin-left: 4px;
    }
}

.faqHeader {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 20px;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #2c2e30;
}

.faqs {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
}

.faqs .faq {
    margin: 15px;
    padding: 15px;
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 18px;
    padding-right: 80px;
    transition: all 0.4s ease;
    padding-bottom: 30px;
}

.faqs .faq .faq-question::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-image: url("../../images/plus.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
    margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
    background-image: url("../../images/minus.svg");
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}

.faq-answer{
    font-size: 15px;
}

.footerIndex {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.contactHeader {
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.contact-form {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column wrap;
    padding: 1%;
}

.contactForm {
    width: 357px;
    height: 41px;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 4px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7e8085;
}

.contactButton {
    margin-top: 40px;
    padding-left: 25px;
}

@media
 only screen and (max-width: 600px) {
    .footerIndex{
        width: 100%;
    }
    .contact-form{
        margin: auto;
    }
    .contactButton{
        margin: auto;
    }
 }

.press {
    display: table;
    /* Required for table-cell to work on li's */
    padding: 0;
    /* Override defaults for lists */
    margin: 0;
    /* Override defaults for lists */
    width: 100%;
    /* Get the row full width */
    text-align: center;
    /* Centre align grid items */
}

.press li {
    display: table-cell;
    /* Get all images to show in a row */
    text-align: center;
    /* Centre align the images */
}
.smallLogo{
    width: 70px;
    height: 100px;
}

@media (max-width: 960px) and (min-width: 501px) {
    .press li {
        width: 50%;
    }
    /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
}

@media (max-width: 500px) {
    .press li {
        width: 100%;
    }
    /* On small screens, show one logo per row */
    .loginButton {
       display: none;
    }
    .registerButton{
        display: none;
    }
    .smallLogo{
        display: none;
    }

}

@media (max-width: 960px) {
    .press {
        display: block;
    }
    .press li {
        display: inline-block;
    }
}

.ui.tabular.menu .item {
    width: 23.5%;
font-size: 14px;
}

.langSelection{
    width: 140px;
    width: 10%;
    height: 45px;
    margin: 1px 0 0;
    padding: 9px 5px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 100px;
}

.Lang{
    width: 78px;
    height: 18px;
    font-family: PloniDL1.1AAA;
    font-size: 18px;  
    font-weight: bold;  
    font-stretch: normal;
 
    font-style: normal;
  
    line-height: 1;
  
    letter-spacing: 0.51px;
  
    text-align: right;
  
    color: #ffffff;
}

.faq-answer{
    font-size: 14px;
}