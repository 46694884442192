.addButton {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15x;
    padding-right: 15px;
    margin: 2%;
    width: 120px;
    height: 46px;
    border-radius: 24px;
    background-color: #ff8738;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.contentArea {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: space-between;
    padding: 2%;
}

.leftCard {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    flex-direction: column;
    width: 30%;
    height: 26px;
    font-family: PloniDL1.1AAA;
    font-size: 21.8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #97a50f;
}
.cardTitle{
font-size: 16px;
}

.mediaArea {
    height: auto;
    width: auto;
    
    max-width: 220px;
    max-height: 90px; 
    /* margin: auto;  */
}

audio {
    width: 150px;
    display: block;
    margin: 20px;
}

audio:nth-child(2) {
    width: 250px;
}

audio:nth-child(3) {
    width: 350px;
}

.saveButton {
    width: 171px;
    height: 46px;
    border-radius: 24px;
    background-color: #97a425;
}

.photoItem {
    margin:auto;
    width : 140px;
}