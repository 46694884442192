.side-drawer { 
    height : 100%;
    background : white;
    position : fixed;
    top: 0;
    right: 0;
    width: 40%;
    min-width: 360px;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
 }
 .side-drawer.open {
    transform: translateX(0);
    /* for IE */
  box-shadow: 0 0 0 1000px rgba(0,0,0,.4);
  /* for real browsers */
  box-shadow: 0 0 0 100vmax rgba(0,0,0,.4);
 }
.total{
   padding: 0px 4% 0px 4%;
}
 @media (max-width: 960px) {
   .side-drawer { }
}