.reviewsZone{ margin-bottom: 95px; }

.reviewZone{
    width: 390px;
    
    background: #FFFFFF;
box-shadow: 0px 2px 40px rgba(96, 96, 96, 0.15);
border-radius: 8px;

margin: auto;

}

.topReview{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3% 7% 1% 7%; 
}

.picReview{
    width: 80px;
    border-radius: 18%;
    
}
.reviewName{
    font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 17px;

/* identical to box height */
display: flex;
align-items: center;
padding-top: 16px;
padding-bottom: 9px;
    }

.reviewContent{
    width: 90% ;
    /* margin: auto; */
    padding: 0 25px 10px 25px;
    text-align:right;
}

.reviewDate{
    padding-top: 16px
}