.previewArea {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 15%;
  margin-left: 5%;

  width: 60%;
}

.tourPreview {
  /* width: 30vw !important; */
  /* width: 40%; */
  height: 120vh;
  background-image: url("../images/blankPhoto.png");
  background-repeat: no-repeat;
  padding: 3%;
  padding-top: 65px;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: hidden;
  margin-left: 50px;
  background-size: 100% auto;
  width: 100%;
  margin-top: 50px;
}

.img {
  max-width: 100%;
  height: auto;
}

.tourImg{
  height:100%;
}

.topHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3%;
  height: 400px !important;
  margin-bottom: 50px !important;
}

.tourName {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  letter-spacing: normal;
  text-align: right;
  color: #434040;
}

.tourPrice {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.28px;
  text-align: right;
  color: #ff8900;
}

.tourType {
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.4px;
  color: #ff8900;
}

.subHeader {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 8%;
  height: 80px !important;
}

.subHeaderStop {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 97%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.iconItem {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 4%;
  width: 45%;
}

.iconItem img {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

.descHeader .notesHeade {
  font-size: 18px;
  height: 28px;
  letter-spacing: 0.06px;
}

.tourDesc .tourNotes {
  font-family: PloniDL1.1AAA;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.05px;
  color: #9c9c9c;
}

.tourDescription {
  margin-top: 240px;
  padding: 3%;
}

.tourNotes {
  margin-top: 340px;
  height: 150px;
  padding: 3%;
}

.ratingStar {
  color: #ff0000;
}

.tourRate {
  margin: 5px;
  color: #ffffff;
  font-size: 15px;
}

.List {
  width: 200px;
  height: 210px;
  background-color: #ffffff;
}

.pruchaseButton {
  width: 90%;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.21);
  background-color: #f8a12a;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  text-align: center;
  color: #ffffff;
  margin: auto;
  margin-top: 220px;
}

.guideInfo{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 35px;
}

.guideName{
  margin: 1px;
  width: 99px;
  height: 24px;
  font-family: PloniDL1.1AAA;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  text-align: right;
  color: #434040;
}

.guidePic img{
  width: 35px;
  height: 35px;
}

.guideRate{
  margin-left: 42px;
  margin-top: 13px;
}

.viewProfile{
  width: 98px;
  height: 24px;
  font-family: PloniDL1.1AAA;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  text-align: right;
  color: #ff8900;
  margin-left: 12%;
}

.imgCarousel{
  height: 400px !important;
  margin-bottom: 550px !important;
  margin-top: 50px !important;
}

.DetailNguide{
  margin-top: 60px;
}