.formBG {
  background-color: #39415e;
  text-align: center;
}

.deadZone {
  display: flex;
  align-items: center;
  background-image: url('/assets/mapBG.png');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainApp {
  display: flex;
  justify-content: left;
  align-items: left;
  height: 100vh;
}

.switchContext {
  margin-left: 17%;
  /* width: 100vw; */
  width: 100%;
}