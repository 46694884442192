.salesTable {
  padding: 0%;
  margin: 7%;
}

.dataFilter{
  display: flex;
  flex-direction: row;
  width: 50%;
}
