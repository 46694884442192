.saleVideo{
    margin: auto;
    width: 57%;
    border-radius: "10px";
    
}
.saleHeader{
    margin: auto;
    padding-bottom: 12px;
    font-size: 34px;
    font-weight: bold;
    color: azure;
    padding-bottom: 10px;
}

.mainSentense{
    margin: auto;
    font-size: 18px;
    font-weight: bold;
    color: azure;
    padding-bottom: 30px;
}
.cartIcon{
    position:absolute;
  top:3%;
  right:8%;
}

.saleDescription{
    width: 60%;
    text-align: center;
    margin: auto;
    padding-top: 15px;
    font-size: 18px;
    line-height: 1.5;
    direction: rtl;
}

.commonQuestion{
    text-align: center;
    margin: auto;
    padding-top: 30px;
    direction: rtl;
 
}
.commonQuestionQestion{ 

    border-width:9px;
    border-style:solid;
    border-color:black;
    margin-left:32%;
    margin-right:32%;
    border-style: outset;
    border-radius: 25px;
    margin-bottom: 35px;
}


.commonQuestionQestionQ{
    padding-top: 30px;
    padding-bottom: 18px;
    text-align: center;
    font-size: 22px;
}

.commonQuestionQestionA{
    padding-top: 16px;
    padding-bottom: 18px;
    text-align: center;  
    font-size: 16px; 
    line-height: 1.3;
}

.prodDetailsIcon{
    margin: 10px auto 10px auto;
    text-align: center;
    display:block;
    border-width:5px;   
}



.imageIcon{
    margin-bottom: 8px;
    width: 60px;
    height: 60px;
}

.commonQuestionQestionImg{
    width: 18%;
}
.commonQuestionQestionImg1{
    width: 65%;
}
.commonQuestionHeader{
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #2c2e30;
}

.businessHeader{
    margin : 30px 0 15px 0
}

.carousellPic{
    width: 320px;
    height: 320px;
    border-radius: 10%;
    margin:auto;
    border-width:9px;
    border-style:solid;
    border-color:black;
    border-style:ridge ;
}

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.perchase-bar {
    position: fixed;
    top: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    background-color: #38405e;
    width: 400px;
    height: 70px;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
  }
/* Style the social media icons with color, if you want */
.perchase-bar button:hover {
    background-color: #e4e4e451;
  }

  /* Style the icon bar links */
.perchase-bar a {
    /* display: block; */
    text-align: center;
    padding: 14px;
    transition: all 0.3s ease;
     color: white;
    font-size: 20px; 
  }

.saleButton{
    margin: 15px auto;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    background-color: #ff8738 ;
}

.orangeButton{
    background-color: rgba(255, 137, 0, 0.6);
}

.returnTop{
    background-color: rgba(255, 137, 0, 0.6);
    margin:35px auto;
    display:block;
    
}
.returnTopBar{position: relative;top: 50px }

.tourButton {
    /* width: 571px; */
    width: 65%;
    height: 160px;
    border-radius: 8px;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(241, 241, 241, 0.753);
    /* margin-left: 5%;
    margin-top: 3%; */
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: row;
    margin:1% auto 1% auto;
    text-align: center;
}

/*CHANGES 2/10*/
.tourButton {
    /* width: 571px; */
    width: 65%;
    height: 380px;
    border-radius: 56px;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(241, 241, 241, 0.753);
    /* margin-left: 5%;
    margin-top: 3%; */
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: row;
    margin:1% auto 1% auto;
    text-align: center;
    border:10px  ;  
    border-style:outset;
    border-color: rgba(255, 137, 0, 0.6); 
}

.toursMenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.tourButtonText{
    text-align: center;
    display: block;
    margin-left: 10px;
}
.tourButtonImg{
    padding-right: 25px;
    height: 90%;
}
.buttonText{    
    margin: auto;
    padding-bottom: 5px;       
    color: rgb(15, 15, 15);
    text-align: center;
}

.bigTitle{
    font-weight: bold;
    font-size: 30px;
}

.smallText{
    font-size: 20px;
}

.returnButton{
    background-color: #8391c2;
    background-repeat:no-repeat;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    margin :1.5% auto 3% auto;
    font-size: 25px;
    padding: 2px;
    border-radius: 12px;
    
}

.hederSale{
    height:  auto;
}

.mainApp{
    height: 0px;
}

.hrLine{
    width: 90%;
    margin: auto;
    border-top: 1px dotted red;
}

@media only screen and (max-width: 600px) {
    .saleVideo{
        margin: auto;
        width: 100%;
        border-radius: "10px";
    }

    .saleHeader{
        font-size: 24px;
    }
    .bigLogo{
        width: 65%;
    }
    .saleDescription{
        width: 80%;
        line-height: 1.55;
        direction: rtl;
        font-size: 17px;
    }
    .commonQuestionQestionImg{
        width: 34%;
    }
    .commonQuestionQestionImg1{
        width: 80%;
        height: 70%;
    }

    .businessPicFrame{
        width:65%;
       height: 95%;
    }

    .commonQuestionQestion{ 

        border-width:5px;
        border-style:solid;
        border-color:black;
        margin-left:20%;
        margin-right:20%;
        border-style: outset;
        border-radius: 25px;
    }
    .bigTitle{
        font-weight: bold;
        font-size: 18px;
    }
    
    .smallText{
        font-size: 15px;
    }
    .tourButton {
        /* width: 571px; */
        width: 85%;
       /*  height: 200px; */
    }
       /* changes 2/10 */
    .tourButton {
        width: 85%;
       /*  height: 520px; */
        flex-direction: column;
    }
    .tourButtonImg{
        /* padding-left: 3%; */
        height: 48%;
        margin: auto;
    }
    .headerIndex {
        /*width: 1920px;*/
        width:100%;
        height: 800px !important;
    }
}

.titleTest{
    background: #FFFFFF;
border: 3px solid #E0E0E0;
box-sizing: border-box;
border-radius: 7px;
}