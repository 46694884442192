.outerStore{
   /*  margin: 25px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.productsList{
    width: 90%;
}
.allCart{
    width: 30%;
    z-index: 100;
}

.allDirectCart{
    background-color: #39415e
}

.cart-items{
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 18px;
}

.cart-header{
    font-family: PloniDL1.1AAA;
    font-size: 19px; 
    font-weight: bold; 
    font-stretch: normal; 
    font-style: normal; 
    line-height: normal; 
    letter-spacing: 0.06px;
    text-align: center;
    color: var(--greyish-brown);
}

.cart-item{
    width: 95%;
    /* max-height: 180px; */
    margin: 12px auto;
    border-radius: 5px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #6e6d71;
    font-family: PloniDL1.1AAA;
    padding: 2%;
    margin:auto;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(127, 126, 126, 0.5);
    margin-bottom: 3%;
}
.right {
    text-align: right;
    padding-bottom: 4%;
  }
.cartImg{ 
    width: 30%;
}
.cartTitle{
    font-size: 19px; 
    font-weight: bold; 
    margin: 0 6px 0 6px;
    width: 100%;
    text-align: right;

}
.cartTop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2%;
    
}

.infoCarousel{
    margin: auto;
    width: auto;
   /*  height: auto; */
}

.productsLogo {
    /*width: 1920px;*/
    width: 85%;
    position: relative;
    background: #38405e;
    text-align: center;
    overflow: hidden;
    padding-top: 12px;
   /*  margin: 0px auto 25px; */
    border-radius: 32%;
}

.bigLogo{
    width: 22%;

}
.zuzuBigLogo{
    width: 15%;
}

.priceText{
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 6px;
}

@media only screen and (max-width: 700px) {
    .bigLogo{
        width: 45%;
    }
    .zuzuBigLogo{
        width: 28%;
    }
}

.successPayment{
    width: 50%;
    margin: 35px auto;
    
}

.contentCartArea{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.successTitle{
    font-size: 22px; 
    font-weight: bold; 
    color: #f8a12a;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}

.successMassage{
    width: 180px;
    text-align: center;
    margin: auto;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
}

.actionButton {
    border-radius: 24px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.21);
    background-color: #f8a12a;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    font-style: bold;
    margin-top: 3%;
    
}
 .proceed{
     /* width: 40%; */
     height: 35px;
     margin: auto;
 }

 .origPrice {
    -webkit-text-decoration-line: line-through; /* Safari */
    text-decoration-line: line-through; 
 }

 .outerPayTop{
     width: 95%;
     display: flex;
     flex-direction: row;
     margin:  5px auto 15px auto;
     position:sticky;
     top: 0;
     background-color: #EAEAEA;
 }

 .iframePay{
     width: 95%;
 }

 .onBoardingPic .carouselPic{
     height: 500px;
 }

 .carouselPic{
     height: 500px;
 }

.couponZone{
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
}

.couponText{
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;  
    font-size: 15px; 
    line-height: 1.3;
}
.total{
text-align: center;
}
.totalPay{
    padding: 2px;
    text-align: center;  
    font-size: 18px; 
    line-height: 1.3;
}

 @media only screen and (max-width: 700px) {
     .onBoardingPic .carouselPic{
         height: 300px;
         width: 95%;
     }
     .cartImg{ 
        width: 45%;
    }
    .proceed{
         width: 60%;
    }
 }

 .caroulelContext{
     text-align: center;
     margin: 0px;
 }
 .carouselHeder{
     font-size: 26px;
     text-align: center;
     width: 95%;
 }

.directTop{
    display: flex;
    flex-direction: column;
}

 .directPayHeader{
     padding-top: 5px;
     margin: auto;
     text-align: center;
     direction: rtl;
     color: #EAEAEA;
 }

 .directPayIntro{
     padding-top: 8px;
     padding-bottom: 15px;
     letter-spacing: 0.5px;
     line-height: 22px;
     width: 60%;
     margin: auto;
     text-align: center;
     direction:rtl;
     color: #EAEAEA;
 }

 .directCart{
     width: 95%;
     margin: auto;
 }

 .socialLogin {
     margin: auto;
     margin-top: 1%;
 }

 .socialLogin{
    margin: auto;
 }

 .loginText
 {
     width: 100%;
     text-align: center;
     margin: 20px;
     direction:rtl;
     font-size: 16px;
     
 }