/* Advanced Checkbox Hack */
body {
  -webkit-animation: bugfix infinite 1s;
}
@-webkit-keyframes bugfix {
  from {
    padding: 0;
  }
  to {
    padding: 0;
  }
}

input[type="checkbox"] {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

label {
  display: none;
  cursor: pointer;
  user-select: none;
}

/* custom-dropdown */
nav[role="custom-dropdown"] {
  position: relative;
  width: 90%;

  a,
  label {
    color: #ccc;
    text-decoration: none;
    font: 1em sans-serif;
    transition: color 0.3s ease-in-out;
  }
  .responsive {
    width: 100%;
  }
  ul {
    padding: 0;
    margin: 0 auto;
    width: 100%;
    list-style: none;

    > li {
      float: left;
      padding: 12px 10px;
      // width: 15%;
      //  background-image: $img_bg;
      text-align: center;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      transition-property: box-shadow, color;
      font-size: 16px;
      // box-shadow: 0 0.05em 0.25em 0 rgba(0, 0, 0, 0.35);
      cursor: pointer;

      &:hover {
        background-color: rgba(238, 169, 22, 0.371);
        color: #fff;
        box-shadow: 0 0.05em 0.25em 0 rgba(0, 0, 0, 0.35),
          inset 0 0 0 2em rgba(0, 0, 0, 0.3);

        > a {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  nav[role="custom-dropdown"] {
    position: relative;
    width: 100%;

    a,
    label {
      color: #ccc;
      text-decoration: none;
      font: 1em sans-serif;
      transition: color 0.3s ease-in-out;
    }

    ul {
      padding: 0;
      margin: 0 auto;
      width: 100%;
      white-space: nowrap;
      display: inline;
      list-style: none;

      > li {
        float: left;
        padding: 12px 9px;
        // width: 15%;
        //  background-image: $img_bg;
        text-align: center;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        transition-property: box-shadow, color;
        font-size: 14px;
        // box-shadow: 0 0.05em 0.25em 0 rgba(0, 0, 0, 0.35);
        cursor: pointer;

        &:hover {
          background-color: rgba(238, 169, 22, 0.371);
          color: #fff;
          box-shadow: 0 0.05em 0.25em 0 rgba(0, 0, 0, 0.35),
            inset 0 0 0 2em rgba(0, 0, 0, 0.3);

          > a {
            color: #fff;
          }
        }
      }
    }
  }
}

/* small screens */
@media screen and (max-width: 500px) {
  html,
  body {
    margin: 0;
  }

  nav[role="custom-dropdown"] {
    ul {
      width: 95%;
      display: none;
      height: 100%;
    }

    label {
      position: relative;
      display: block;
      width: 100%;
      min-height: 2.25em;
      padding: 0.45em;
      font-size: 1.1em;
      margin: 0;
      border: 0.15em solid rgba(255, 255, 255, 0);

      //  background-image: $img_bg;
    }

    label:after {
      position: absolute;
      right: 0.25em;
      top: 0;
      content: "\2261";
      font-size: 1.8em;
      float: left;
    }

    input[type="checkbox"]:checked ~ label:after {
      color: #222;
      width: auto;
    }

    input[type="checkbox"]:checked ~ ul {
      display: block;

      > li {
        width: 100%;
        /*  opacity: 0.8; */
        text-align: left;
        font-size: 14px;

        font-weight: 900;

        font-stretch: normal;

        font-style: normal;

        line-height: normal;

        letter-spacing: normal;

        color: #ffffff;
        border-bottom: 0.1px solid white;
        /*  &:after {
          position: absolute;
          right: 0.25em;
          content: "\203A";
          font: bold 1.4em sans-serif;
        } */
      }
    }
  }
}
