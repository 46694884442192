.generalModal {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #ffffff;
  z-index: 1000;
  padding: 15px;
  border-radius: 15px;
}

.generalModal:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.modalTitle {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #f8a12a;
  margin: 2%;
  padding: 6px;
}

.modalText {
  margin: 3%;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #6e6d71;
  white-space: pre-wrap;
}

.nextButton {
  margin: 2%;
}

.BfirstModal {
  position: absolute;
  top: 15%;
  left: 37%;
  width: 530px;
  height: 230px;
}

.BsecondModal {
  position: absolute;
  top: 2%;
  left: 58%;
  width: 460px;
  height: 210px;
}

.BaddressModal {
  position: absolute;
  top: 8%;
  left: 56%;
  width: 460px;
  height: 210px;
}

.BaddressDescModal {
  position: absolute;
  top: 20%;
  left: 56%;
  width: 460px;
  height: 210px;
}

.BaddPicModal {
  position: absolute;
  top: 40%;
  left: 56%;
  width: 460px;
  height: 210px;
}

.BlatLngModal {
  position: absolute;
  top: 45%;
  left: 56%;
  width: 460px;
  height: 210px;
}

.BsaveModal {
  position: absolute;
  top: 60%;
  left: 56%;
  width: 460px;
  height: 210px;
}

.generalMarker {
  position: absolute;
  border: 20px solid rgba(247, 160, 57, 0.6);
  z-index: 1000;
  transition: 300ms ease-in;
}

.peakLocMarker {
  position: absolute;
  top: 13%;
  left: 18%;
  width: 660px;
  height: 740px;
}

/*arrow style*/

.instractionArrow {
  position: absolute;
  left: 50%;
  z-index: 1000;
  width: 110px;
  height: 100px;
  transition: 300ms ease-in;
}

.mainNavTop {
  top: 5.5%;
}

.firstArw {
  transform: rotate(-90deg);
  left: 46%;
}

.secondArw {
  transform: rotate(-90deg);
  left: 36.5%;
}

.thirdArw {
  transform: rotate(-90deg);
  left: 28.5%;
}

.fourthArw {
  transform: rotate(-90deg);
  left: 20.5%;
}

.addressArw {
  top: 27%;
  left: 49%;
}

.descAddArw {
  top: 39%;
  left: 42%;
}

.addPicArw {
  top: 64%;
  left: 30%;
}

.latLngArw {
  transform: rotate(-45deg);
  top: 64%;
  left: 32%;
}

.saveAddArw {
  transform: rotate(-90deg);
  top: 71%;
  left: 44.5%;
}