.productItem {
     width: 95%; 
    border-radius: 5px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #6e6d71;
    font-family: PloniDL1.1AAA;
    padding: 2%;
    margin:auto;
    border-radius: 20px;
    box-shadow: 0 0 6px 0 rgba(127, 126, 126, 0.5);
    margin-bottom: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.prodSentense{
    margin-bottom: 18px;
}
.tourImg{
    width: 160px;
    height: 160px;
    margin :auto;
}

.prodImg{
    width: 0%;
    display: flex;
    flex-direction: column;
}

.prodName{
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: 0.06px;
    color: #434040;
    padding: 8px;
}

.productTop{
    display: flex;
    flex-direction: column;
    width: 100%;
   
}

.descTour{
    width: 100%;
    text-align: center;
    font-size: 16px;
}
.prodPrice{
font-size: 19px;

}

.prodDetails{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin:  10px auto ;
    width: 85%;
    font-size: 18px;
}
.prodDetailsIcon{
    display: flex;
    flex-direction: row;
    padding: 0%;
    width: 40%;
    margin: auto;
}

.imageIcon{
    width: 20px;
    height: 20px;
}
.tourDifficulty {
    padding: 0px 5px 0px 5px;
}

.tourDuration{
    padding: 0px 5px 0px 5px; 
}
.tourDistance{
    padding: 0px 5px 0px 5px; 
}
.tourLocation{
    padding: 0px 5px 0px 5px; 
}

@media (max-width: 960px) {
    .productItem
    {flex-direction: column;}
}